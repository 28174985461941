<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <b-modal
      class="p-2"
      id="export-modal"
      hide-header-close
      hide-header
      hide-footer
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('export-modal')"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center pb-3 btn-auto" style="font-size: 18px">
        {{ $t("asset.choose_ex") }}
      </p>
      <b-row align-h="center">
        <button class="px-2 download-file" @click="onExport(false)">
          {{ $t("btn.dl_file") }}
        </button>
      </b-row>
    </b-modal>
    <b-modal
      id="filter-modal"
      @cancel="handleclear"
      :cancel-title="$t('btn.clear')"
      hide-header-close
      hide-header
      :ok-title="$t('btn.conf')"
      @ok="handleFilter"
    >
      <b-row>
        <b-col class="text-end" cols="2"> </b-col>
        <b-col class="text-center" cols="8">
          <h3 class="text-dark">{{ $t("filter.dt_fil") }}</h3>
        </b-col>
        <b-col class="text-end" cols="2">
          <button
            class="close_modal text-light border-none"
            @click="$bvModal.hide('filter-modal')"
          >
            <b-icon-x scale="1.5" />
          </button>
        </b-col>
      </b-row>
      <label class="mt-2">{{ $t("asset.region_province") }}</label>
      <v-select
        :options="optionSector"
        :reduce="(option) => option.value"
        v-model="filterOptions.sector"
        class="dropdown-style"
        :clearable="false"
      />
      <label class="mt-2">{{ $t("asset.branch") }}</label>
      <v-select
        :options="optionBranch"
        :reduce="(option) => option.value"
        v-model="filterOptions.branch"
        class="dropdown-style"
        :clearable="false"
      />
      <label class="mt-2">{{ $t("filter.cat") }}</label>
      <v-select
        :options="optionCategory"
        :reduce="(option) => option.value"
        v-model="filterOptions.category"
        class="dropdown-style"
        :clearable="false"
      />
      <label class="mt-2">{{ $t("filter.sub_cat") }}</label>
      <v-select
        :options="optionSubCategory"
        :reduce="(option) => option.value"
        v-model="filterOptions.sub_category"
        class="dropdown-style"
        :clearable="false"
      />
    </b-modal>
    <div class="responsive-bar">
      <Footer></Footer>
      <div>
        <div class="back-icon">
          <img
            src="../../assets/previous.svg"
            width="30"
            @click="$router.back()"
          />
        </div>
        <h1 class="header-text">
          <div class="topic">
            {{ $t("maintenance_plan.maintenance_plan") }}
          </div>
        </h1>
        <div class="py-3">
          <div class="container-fluid">
            <!-- {{ planDetail }} -->
            <h3>{{ planDetail.name }}</h3>
            <p>
              {{ moment(roundDate).format("DD MMMM YYYY") }}
              เวลา
              {{ moment(roundDate).format("HH:mm") }}
            </p>
            <div class="text-start">
              <p>
                {{ $t("maintenance_plan.vendor") }} :
                {{ planDetail.vendor.name }}
              </p>
              <p>
                {{ $t("maintenance_plan.detail") }} : {{ planDetail.detail }}
              </p>
              <p>
                {{ $t("maintenance_plan.asset_amount") }} :
                {{ totalRows }}
              </p>
            </div>
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center flex-wrap">
                <button
                  v-if="!isNativeApp"
                  @click="$bvModal.show('export-modal')"
                  class="mx-1 outline-button table"
                  style="height: 45px"
                >
                  <div
                    style="display: flex; align-items: center; justify-content: space-evenly;"
                  >
                    <b-icon-upload font-scale="1.5" style="margin-right: 5px" />
                    {{ $t("btn.ex_file") }}
                  </div>
                </button>
              </div>
              <div class="d-flex my-2">
                <select
                  v-if="page === 'plan'"
                  class="form-control input-custom"
                  style="width: 200px"
                  v-model="roundDate"
                >
                  <option
                    :value="round.round_date"
                    v-for="round in planRoundList"
                    :key="round.round_date"
                    >{{
                      moment(round.round_date).format(
                        `${$t(
                          "maintenance_plan.maintenance_month_round"
                        )}MMM YYYY`
                      )
                    }}</option
                  >
                </select>
                <span
                  class="filter-box"
                  style="border-radius: 10px; display: flex; padding-left: 1rem; margin-right: 1rem;"
                >
                  <img src="@/assets/search-white.svg" width="20px" />
                  <input
                    type="text"
                    class="form-control filter-box input-custom"
                    style="width: 250px min-width: 250px"
                    v-debounce:700ms="searchByText"
                    :placeholder="$t('home.search')"
                    v-model="filterOptions.searchText"
                  />
                </span>

                <!-- Filter Button -->
                <button
                  class="filter"
                  style="border: none"
                  type="button"
                  id="button-addon2"
                  @click="$bvModal.show('filter-modal')"
                >
                  <img src="@/assets/filter-white.svg" />
                </button>
              </div>
            </div>
            <MaintenanceDetailTable
              class="my-4"
              :dataTable="planData"
              :roundDate="roundDate"
              :page="currentPage"
              :perPage="perPage"
            />
          </div>
          <div
            class="d-flex justify-content-center mt-3"
            v-if="planData.length > 0"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
        <div class="line-break"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import MaintenanceDetailTable from "@/components/CustomTable/MaintenancePlanDetailTable.vue";
import Loading from "vue-loading-overlay";
import moment from "moment";
import { mapGetters } from "vuex";
import axios from "axios";
import baseUrl from "../../util/backend";
import { authHeader } from "../../store/actions";

export default {
  components: { Footer, MaintenanceDetailTable, Loading },
  data() {
    return {
      isLoading: true,
      planId: null,
      roundDate: null,
      page: null,
      id_company: null,
      selectedMenu: "plan",
      currentPage: 1,
      perPage: 100,
      totalRows: 1,
      filterOptions: {
        searchText: null,
        start_date: null,
        end_date: null,
        sector: null,
        branch: null,
        category: null,
        sub_category: null,
      },
      planDetail: {},
      planData: {},
      planRoundList: [],
    };
  },
  filters: {
    formatDate(value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY");
    },
  },
  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
      permissionStatus: "permissionStatus",
      optionChoice: "getfilterOption",
    }),
    optionSector() {
      const opt =
        this.optionChoice.optionSector?.map((sector) => {
          return {
            label: sector.name,
            value: sector.sector_id,
          };
        }) || [];
      return [{ label: "ทั้งหมด", value: null }, ...opt];
    },
    optionBranch() {
      const branch = this.optionChoice.optionBranch?.filter(
        (opt) => opt.sector_id === this.filterOptions.sector
      );
      const opt =
        branch?.map((b) => {
          return {
            label: b.name,
            value: b.branch_id,
          };
        }) || [];
      return [{ label: "ทั้งหมด", value: null }, ...opt];
    },
    optionCategory() {
      const opt =
        this.optionChoice.optionCategory?.map((cat) => {
          return {
            label: cat.name,
            value: cat.id_category,
          };
        }) || [];
      return [{ label: "ทั้งหมด", value: null }, ...opt];
    },
    optionSubCategory() {
      const category = this.optionChoice.optionCategory?.find(
        (opt) => opt.id_category === this.filterOptions.category
      );
      const opt =
        category?.sub_categories?.map((c) => {
          return {
            label: c.name,
            value: c.id_sub_category,
          };
        }) || [];
      return [{ label: "ทั้งหมด", value: null }, ...opt];
    },
  },
  watch: {
    currentPage() {
      this.loadPageData();
    },
    "filterOptions.sector"() {
      this.filterOptions.branch = null;
    },
    "filterOptions.category"() {
      this.filterOptions.sub_category = null;
    },
  },
  methods: {
    moment,
    async handleclear() {
      // this.countingStatus = "ทั้งหมด";
      this.currentPage = 1;
      this.filterOptions = {
        searchText: null,
        start_date: null,
        end_date: null,
        sector: null,
        branch: null,
        category: null,
        sub_category: null,
      };
      this.loadPageData();
    },
    handleFilter() {
      this.loadPageData();
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },

    async searchByText() {
      this.loadPageData();
    },
    async onExport(isMail) {
      this.isLoading = true;
      await axios
        .post(
          `${baseUrl()}maPlan/export/excel/${this.id_company}`,
          {
            preventive_ma_plan_id: this.planId,
            date: moment(this.roundDate).format("YYYY-MM-DD"),
          },
          { ...authHeader(), responseType: "blob" }
        )
        .then((response) => {
          if (!isMail) {
            const blob = new Blob([response.data], {
              type: "application/vnd.ms-excel",
            });
            const elink = document.createElement("a");
            elink.download = `${this.$t(
              "maintenance_plan.maintenance_plan"
            )}.xlsx`;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.$bvModal.hide("export-modal");
      this.isLoading = false;
    },
    async loadPlanRoundList() {
      this.isLoading = true;
      const plan = await this.$store.dispatch("loadPlanRoundList", {
        id_company: this.id_company,
        planId: this.planId,
        page: this.currentPage,
        limit: this.perPage,
      });
      console.log("loadPlanRoundList:", plan.plan.detail);
      this.totalRows = plan.plan.count;
      this.planData = plan.plan.preventive_ma_plan_assets
        ? plan.plan.preventive_ma_plan_assets
        : [];
      this.planDetail = plan.plan.detail;
      this.planRoundList = plan.round;
    },
    async loadPageData() {
      this.isLoading = true;
      this.$store.dispatch("loadOptionChoice");
      console.log("checkpoint::");
      const plan = await this.$store.dispatch("loadPlanRoundList", {
        id_company: this.id_company,
        planId: this.planId,
        page: this.currentPage,
        limit: this.perPage,
        filterOptions: this.filterOptions,
      });
      console.log("loadPageData:", plan.plan.detail);
      this.totalRows = plan.plan.count;
      this.planDetail = plan.plan.detail;
      this.planData = plan.plan.rows ? plan.plan.rows : [];
      this.planRoundList = plan.round;

      if (localStorage.getItem("round_date")) {
        this.roundDate = localStorage.getItem("round_date");
        localStorage.removeItem("round_date");
      } else {
        if (this.page === "list") {
          this.roundDate = this.$route.query.date;
        } else {
          this.roundDate = plan.round[0].round_date;
        }
      }
      this.isLoading = false;
    },
  },
  async mounted() {
    this.page = this.$route.params.page;
    this.planId = this.$route.params.id;
    this.id_company = await this.$store.dispatch("getCompanyIdCookie");

    this.loadPageData();
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 14px;
}

.input-custom {
  height: 45px;
  border-radius: 10px 10px 10px 10px;
  margin: 0 5px;
}

.header-text {
  padding-top: 30px;
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font-size: 28px;
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    // margin-bottom: 0px;
  }
}
.gradient-text {
  background: -webkit-linear-gradient(#007afe 0%, #013f81 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.topic {
  padding-top: 2.7rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

.fill-button {
  background: transparent linear-gradient(180deg, #007bff 0%, #003e80 100%) 0%
    0% no-repeat padding-box;
}
.outline-button {
  width: 160px;
  height: auto;
  padding: 5px;
  border-radius: 10px;
  color: $color-active-blue;
  box-shadow: $shadow-default;
  border: solid 2px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(180deg, #007afe 0%, #013f81 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;

  &.table {
    font-size: 14px;
    height: 100%;
    width: auto;
    margin: 0;
    padding: 5px 10px;
  }
}

.filter {
  border-radius: 10px 10px 10px 10px !important;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  margin: 0 !important;

  img {
    width: 40px;
    padding: 0.3rem;
  }
}

.download-file {
  padding: 0 10px 0 10px;
  color: white;
  border-radius: 8px;
  border: none;
  width: 130px;
  height: 34px;
  font-size: 14px;
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#007afe),
      to(#013f81)
    ) 0% 0% no-repeat padding-box;
}
.email-me {
  background: transparent;
  color: #007afe;
  border: solid 2px;
  border-radius: 8px;
  width: 130px;
  height: 34px;
  font-size: 14px;
  margin-right: 10px;
}

.dropdown-style {
  background-color: white;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}

.dropdown-style ::v-deep .vs__search {
  background-color: white !important;
}
</style>
